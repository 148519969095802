import React, { Component, Suspense } from "react";
import BrowserLanguage from "./components/Util/BrowserLanguage";
import "./App.css";
import Header from "./components/Layout/Header";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Register from "./components/UserManagement/Register";
import Login from "./components/UserManagement/Login";
import jwt_decode from "jwt-decode";
import setJWTToken from "./securityUtils/setJWTToken";
import { SET_CURRENT_USER } from "./actions/types";
import { logout } from "./actions/securityActions";
import SecuredRoute from "./securityUtils/SecureRoute";
import axios from "axios";
import Footer from "./components/Layout/Footer";
import ProfilePage from "./components/UserManagement/ProfilePage";
import PageNotFound from "./components/Layout/PageNotFound";
import SuccessPage from "./components/UserManagement/SuccessPage";
import EmailVerification from "./components/UserManagement/EmailVerification";
import ResetPassword from "./components/UserManagement/ResetPassword";
import AccountVerification from "./components/UserManagement/AccountVerification";
import ComingSoon from "./components/Util/ComingSoon";
import ChangePassword from "./components/UserManagement/ChangePassword";
const jwtToken = localStorage.jwtToken;


if (jwtToken) {
  setJWTToken(jwtToken);
  const decoded_jwtToken = jwt_decode(jwtToken);
  store.dispatch({
    type: SET_CURRENT_USER,
    payload: decoded_jwtToken
  });

  const currentTime = Date.now() / 1000;
  if (decoded_jwtToken.exp < currentTime) {
    store.dispatch(logout());
    window.location.href = "/";
  }
}

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  
  const url = window.location.href;
  if (401 === error.response.status && (url.split("/")[3] !== '')) {
    localStorage.removeItem("jwtToken");
    window.location = '/';
  } else {
    return Promise.reject(error);
  }
});

axios.interceptors.request.use((config) => {
  const language = BrowserLanguage.getDefaultLanguage();
  config.headers.common['Accept-Language'] = language ;
  return config
}, error => {
  return Promise.reject(error)
})



class App extends Component {

  constructor() { 
    super()
    this.state = { loading: true }
  }	
    componentDidMount() {
      this.timerHandle = setTimeout(() => this.setState({ loading: false }), 800);
      
    }  
    componentWillUnmount(){   
     
      if (this.timerHandle) {
        clearTimeout(this.timerHandle);
        this.timerHandle = 0;
      }
      
    }
	
  render() {
    
    return (
      this.state.loading ? <div id="loader"></div> : <><Provider store={store}>
      <Router>
        <div className="App">
          <Suspense fallback="loading">
          </Suspense>
          <Header />
          <Switch>
            {
              //Public Routes
            }
            <Route exact path="/" component={Login} />
            <Route exact path="/auth/register" component={Register} />
            <Route exact path="/auth/login" component={Login} />
            <Route exact path="/auth/emailVerification" component={EmailVerification} />
            <Route exact path="/auth/SuccessPage" component={SuccessPage} />
            <Route exact path="/auth/resetPassword" component={ResetPassword} />
            <Route exact path="/auth/activateAccount" component={AccountVerification} />
            <Route exact path="/auth/resources" component={ComingSoon} />
            <Route exact path="/auth/application" component={ComingSoon} />
            <Route exact path="/auth/api" component={ComingSoon} />
            <Route exact path="/auth/forum" component={ComingSoon} />
            {
              //Private Routes
            }
            <SecuredRoute exact path="/auth/UserProfile/:id" component={ProfilePage} />
            <SecuredRoute exact path="/auth/changePassword/:id" component={ChangePassword} />
            {
              //Error Handler
            }
            <Route component={PageNotFound} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </Provider>
    </>
  
      );
  }
}
export default App;
