import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import LanguageIcon from '@material-ui/icons/Language';
import axios from "axios";
import Flags from 'country-flag-icons/react/3x2';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";
import { Link, NavLink } from 'react-router-dom';
import MinervaIOTLogo from '../../images/minerva-iot-logo.png';
import BrowserLanguage from "../Util/BrowserLanguage";

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginBottom: '10px',
    '& > span': {
      maxWidth: 15,
      width: '100%',
      backgroundColor: 'white',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const handleOnclickLogin = () => {
  let element1 = document.getElementById('forumMenu');
  let element2 = document.getElementById('resourcesMenu');

  if (ReactDOM.findDOMNode(element1).classList.contains('Mui-selected')) {
    ReactDOM.findDOMNode(element1).classList.remove('Mui-selected');

  }
  else if (ReactDOM.findDOMNode(element2).classList.contains('Mui-selected')) {
    ReactDOM.findDOMNode(element2).classList.remove('Mui-selected');
  }

}
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    border: 'none',
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
      outline: 'none',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(0.5),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#0099FF',
  },
}));

export default function HeaderLinks(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const align = props.align;
  const [value, setValue] = React.useState(0);
  const [search, setSearch] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const user = props.user;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickDrop = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDrop = (navigationLink) => {
    if (navigationLink[0] !== undefined) {
      redirectForDocs(navigationLink)
    }
    setAnchorEl(null);
  };

  const redirectForDocs = (navigationLink) => {
    axios.get(navigationLink, {
      method: 'GET',
    }).then((response) => {
      if (response.data !== '' || response.data !== undefined) {
        window.open(response.data, '_blank');
      }
    });
  };

  const handleOnclickLanguages = (event) => {

    setAnchorLanguage(event.currentTarget);

  };

  const closeMobileDrawer = () => {
    props.handleDrawerToggle();
  };

  const handleCloseLangMenu = (event) => {
    if (event[0] === undefined) {
      BrowserLanguage.getDefaultLanguage();
      console.log(BrowserLanguage.getDefaultLanguage());
      setSearch(null);
    } else {
      BrowserLanguage.setLanguage(event)
      i18n.changeLanguage(event);
      setSearch(event);
    }
    setAnchorLanguage(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (search != null) {
        const result = await axios.get("/localeLanguage?language=" + search);
        console.log("result=======" + result);
      }
    };

    fetchData();
  }, [search]);



  return (
    <div className={classes.root}>
      <div className={classes.demo2}>
        <NavLink to="/" className="drawer-logo-container" onClick={closeMobileDrawer} style={{ textDecoration: 'none', color: 'unset' }} >
          <img src={MinervaIOTLogo} className="drawer-logo" alt="Logo" />
        </NavLink >
        <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example" orientation={align} style={{ display: (user.firstName !== '') ? 'block' : 'none' }} >
          <StyledTab style={{ display: "none" }} />
          <StyledTab label={t("resources")}
            className="nav-link"
            id="resourcesMenu"
            onClick={handleClickDrop}
          >
          </StyledTab>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            className="my-dropdown-menu"
            open={Boolean(anchorEl)}
            onClose={handleCloseDrop}
            style={{ zIndex: "999999" }}>
            <MenuItem id='tutorial' onClick={() => { handleCloseDrop("/docs/apiGuide"); closeMobileDrawer(); }}>{t("tutorial")}</MenuItem>
            {/* <MenuItem onClick={() => { handleCloseDrop("/docs/apiReference"); closeMobileDrawer(); }}>{t("apiReference")}</MenuItem> */}
            <MenuItem id='api-playground' onClick={() => { handleCloseDrop("/docs/apiPlayground"); closeMobileDrawer(); }}>{t("apiPlayground")}</MenuItem>
          </Menu>
          {/* <StyledTab id="forumMenu" label={t("forum")}
            onClick={closeMobileDrawer}
            // onClick= { () =>
            //   props.handleDrawerToggle()
            // }
            className="nav-link"
            to={{
              pathname: "/auth/forum",
            }} component={Link}
          >
          </StyledTab> */}
          <Button id='sign-in-button' style={{ height: "37px", margin: "5px 10px", color: "white", border: "1px solid whitesmoke" }}
            to={{
              pathname: "/",
            }} component={Link}

            onClick={() => { handleOnclickLogin(); closeMobileDrawer(); }}
          >
            {t("signIn")}
          </Button>
          <div style={{ textAlign: "center" }} id='language-icon'>
            <LanguageIcon style={{ height: "37px", margin: "5px 10px" }}
              className="nav-link language-icon"
              onClick={handleOnclickLanguages}>
            </LanguageIcon>
          </div>
          <Menu
            id="simple-menu1"
            anchorEl={anchorLanguage}
            keepMounted
            className="my-dropdown-menu"
            open={Boolean(anchorLanguage)}
            onClose={handleCloseLangMenu}
            style={{ zIndex: "999999" }}>
            <MenuItem onClick={() => { handleCloseLangMenu("en"); closeMobileDrawer(); }}> <Flags.US title="English" className="lang-flag" />  {t("english")}</MenuItem>
            {/*IconFlagCN MenuItem disabled={true} onClick={() => { handleCloseLangMenu("zh") }}> <IconFlagCN className="lang-flag" />{t("chinese")}</MenuItem> */}
            <MenuItem onClick={() => { handleCloseLangMenu("es"); closeMobileDrawer(); }}> <Flags.ES title="Spanish" className="lang-flag" />{t("spanish")}</MenuItem>
          </Menu>
        </StyledTabs>
        {/* <Typography className={classes.padding} /> */}
      </div>
    </div>
  );
}