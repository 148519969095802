import React, { Component } from 'react';
import { connect } from 'react-redux';
import { validateAccount } from '../../actions/securityActions';

class AccountVerification extends Component {
  componentDidMount() {
    var paramsArr = [];
    const value = this.props.location.search;
    console.log(value);
    paramsArr = value.split('?');

    var id = paramsArr[1].split('&key=')[0].replace('id=', '');
    var key = paramsArr[1].split('&key=')[1];

    if (id != null && key != null) {
      this.props.validateAccount(id, key, this.props.history);
    }
  }

  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
  errors: state.errors,
});

export default connect(mapStateToProps, { validateAccount })(AccountVerification);
