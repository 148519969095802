import { Avatar, Button, Card, CardContent, FormControl, FormHelperText, Grid, Input, InputLabel } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { changePassword, getUser } from "../../actions/UserActions";
import setJWTToken from "../../securityUtils/setJWTToken";
import './ChangePasswordStyle.css';


class ChangePassword extends Component {
	state = {
		isPasswordShown: false,
		isPasswordShown1: false,
		isPasswordShown2: false
	};

	togglePasswordVisiblity = () => {
		const { isPasswordShown } = this.state;
		this.setState({ isPasswordShown: !isPasswordShown });
	};
	togglePasswordVisiblity1 = () => {
		const { isPasswordShown1 } = this.state;
		this.setState({ isPasswordShown1: !isPasswordShown1 });
	};
	togglePasswordVisiblity2 = () => {
		const { isPasswordShown2 } = this.state;
		this.setState({ isPasswordShown2: !isPasswordShown2 });
	};

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			secondaryEmail: "",
			state: "",
			city: "",
			country: "",
			zipCode: "",
			role: "",
			isActive: "",
			password: "",
			confirmPassword: "",
			newPassword: "",
			oldPassword: "",
			address: "",
			profileUrl: "",
			success: false,
			errors: {},
			show: "none",
			edit: false,
			readonly: true,
			tempFirstName: '',
			tempLastName: '',
			tempEmail: '',
			tempPhone: '',
			tempAddress: '',
			tempCity: '',
			tempState: '',
			tempCountry: '',
			tempPasswordTemp: "",
			tempPasswordNew: "",
			tempPasswordOld: "",
			userInitial: "",
		};
		this.onCloseAlert = this.onCloseAlert.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.resetState = this.resetState.bind(this);
	}

	onCloseAlert() {
		this.setState({ success: false, edit: false, readonly: true, errors: {} });
		localStorage.removeItem("jwtToken");
		setJWTToken(false);
		window.location.href = "/auth/login";
	}

	async componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
			if (nextProps.user.firstName !== undefined && nextProps.user.firstName !== '') {
				this.setState({ userInitial: nextProps.user.firstName[0] });
			}
			const {
				id,
				firstName,
				lastName,
				email,
				phone,
				secondaryEmail,
				state,
				city,
				country,
				zipCode,
				role,
				isActive,
				address,
				ciry,
				profileUrl,
				success,
				show,
			} = nextProps.user;
			this.setState({
				id,
				firstName,
				lastName,
				email,
				phone,
				state,
				city,
				country,
				zipCode,
				role,
				isActive,
				secondaryEmail,
				address,
				ciry,
				profileUrl,
				success,
				show
			});
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		this.props.getUser(id, this.props.history);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	resetState() {
		this.setState({
			newPassword: "",
			oldPassword: "",
			confirmPassword: "",
			errors: {
				newPassword: "",
				oldPassword: "",
				confirmPassword: ""
			}
		});
	}

	onSubmit(e) {
		e.preventDefault();
		const newDeveloper = {
			id: this.state.id,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
			phone: this.state.phone,
			secondaryEmail: this.state.secondaryEmail,
			state: this.state.state,
			city: this.state.city,
			country: this.state.country,
			zipCode: this.state.zipCode,
			role: this.state.role,
			address: this.state.address,
			profileUrl: this.state.profileUrl,
			newPassword: this.state.newPassword,
			oldPassword: this.state.oldPassword,
			confirmPassword: this.state.confirmPassword,
			success: this.state.success,
			show: this.state.show
		};
		this.props.changePassword(newDeveloper, this.props.history);
	}

	render() {
		const { isPasswordShown } = this.state;
		const { isPasswordShown1 } = this.state;
		const { isPasswordShown2 } = this.state;
		const { errors } = this.state;
		const { t } = this.props;
		function Alert(props) {
			return <MuiAlert elevation={6} variant="filled" {...props} />;
		}
		return (

			<div className="profileSection changePasswordStyle">
				<Grid container justify="center"  >

					<Grid item xs={12} sm={2}>
					</Grid>
					<Grid item xs={10} sm={8} style={{ height: "475px" }}>

						<div >
							<Grid container style={{ paddingTop: "7%" }}>
								<Grid item xs={12} sm={8} style={{ borderBottom: "1px solid #0099FF", boxShadow: "none !important", textAlign: "left", flexBasis: "100%", maxWidth: "100%", marginBottom: "20px" }}>
									<label id='change-password' style={{ font: "Montserrat,medium ", fontSize: "20px", marginRight: "5px", color: "#003E67" }}>
										{t("changePassword")}
									</label>
								</Grid>
							</Grid>

						</div>

						<Card className="profileBlock">
							<CardContent title="" className="profileHeader">
								{/* <Avatar></Avatar> */}
								<Grid container justify="start">
									<Grid item xs={1} md={12} lg={12} >
										<Avatar id='profile-pic' size={100} className="profile-page-avatar" >{this.state.userInitial}</Avatar>
									</Grid>
								</Grid>
							</CardContent>
							<form onSubmit={this.onSubmit}>
								<CardContent className="changePasswordBody">

									<Grid container>
										<Grid item xs={12} sm={1} md={2} lg={2} xl={2}></Grid>
										<Grid item xs={12} sm={10} md={8} lg={6} xl={5}>
											<FormControl className="form-control">
												<InputLabel required className="input-label">{t("currentPassword")}</InputLabel>
												<Input id='current-password' required name="oldPassword" className="input-field"
													placeholder={t("currentPassword")}
													autoComplete="off"
													value={this.state.oldPassword}
													onChange={this.onChange}
													error={this.state.errors.oldPassword ? true : false}
													helperText={errors.oldPassword}
													type={isPasswordShown ? "text" : "password"}
													endAdornment={
														<InputAdornment position="end">
															<IconButton id='password-icon' className="password-icon"
																tabindex="-1"
																aria-label="toggle password visibility"
																onClick={this.togglePasswordVisiblity}
															>
																{isPasswordShown ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													}
												/>
												<FormHelperText error={true}>{errors.oldPassword}</FormHelperText>
											</FormControl>
											<FormControl className="form-control">
												<InputLabel required className="input-label">{t("newPassword")}</InputLabel>
												<Input id='new-password' required name="newPassword" className="input-field"
													placeholder={t("newPassword")}
													helperText={errors.newPassword}
													autoComplete="off"
													value={this.state.newPassword}
													onChange={this.onChange}
													error={this.state.errors.newPassword ? true : false}
													inputProps={{ pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$', title: 'At least One upper case one lower case one number and one special charcter. Minimum 8 maximum 30' }}
													type={isPasswordShown1 ? "text" : "password"}
													endAdornment={
														<InputAdornment position="end">
															<IconButton id='password-icon' className="password-icon"
																tabindex="-1"
																aria-label="toggle password visibility"
																onClick={this.togglePasswordVisiblity1}
															>
																{isPasswordShown1 ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													}
												/>
												<FormHelperText error={true}>{errors.newPassword}</FormHelperText>
											</FormControl>
											<FormControl className="form-control">
												<InputLabel required className="input-label">{t("confirmPassword")}</InputLabel>
												<Input id='confirm-password' required name="confirmPassword" className="input-field"
													placeholder={t("confirmPassword")}
													autoComplete="off"
													helperText={errors.confirmPassword}
													value={this.state.confirmPassword}
													onChange={this.onChange}
													error={this.state.errors.confirmPassword ? true : false}
													inputProps={{ pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$', title: 'At least One upper case one lower case one number and one special charcter. Minimum 8 maximum 30' }}
													type={isPasswordShown2 ? "text" : "password"}
													endAdornment={
														<InputAdornment position="end">
															<IconButton id='password-icon' className="password-icon"
																tabindex="-1"
																aria-label="toggle password visibility"
																onClick={this.togglePasswordVisiblity2}
															>
																{isPasswordShown2 ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													}
												/>
												<FormHelperText error={true}>{errors.confirmPassword}</FormHelperText>
											</FormControl>

										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={12} sm={1} md={2} lg={2} xl={2} ></Grid>
										<Grid item xs={12} sm={10} md={8} lg={6} xl={5}>
											<div className="my-btn-container btn-right">
												<Button id='update-button ' variant="contained" color="primary" type="submit" className="update-button">{t("update")}</Button>
												<Button id='cancel-button' variant="contained" color="secondary" className="addApplicationBtn" component={Link} to={`/auth/UserProfile/${this.state.id}`}>{t("cancel")}</Button>
											</div>
										</Grid>
									</Grid>
								</CardContent>
							</form>
						</Card>
					</Grid>
					<Grid item xs={12} sm={2}>
						{(this.state.success && (Object.keys(errors).length === 0)) && (
							<Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={this.state.success} autoHideDuration={2000} onClose={this.onCloseAlert}>
								<Alert onClose={this.onCloseAlert} severity="success">
									{t("updatedPasswordMessage")}<br></br>{t("loginWithNewPassword")}
								</Alert>
							</Snackbar>
						)}
					</Grid>
				</Grid >
			</div>
		);
	}
}



// export default ChangePassword;
ChangePassword.propTypes = {
	getUser: PropTypes.func.isRequired,
	changePassword: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	user: state.user.user,
	security: state.security,
	errors: state.errors
});

export default compose(withTranslation(), connect(mapStateToProps, { getUser, changePassword }))(ChangePassword);
