import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { validateCode, resetPassword } from "../../actions/securityActions.js";
import { Grid, Card, CardContent, FormControl, InputLabel, Input, Button, FormHelperText } from '@material-ui/core';
import setJWTToken from "../../securityUtils/setJWTToken";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'

class ResetPassword extends Component {
  state = {
    isPasswordShown: false,
    isPasswordShown1: false
  };

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };
  togglePasswordVisiblity1 = () => {
    const { isPasswordShown1 } = this.state;
    this.setState({ isPasswordShown1: !isPasswordShown1 });
  };
  constructor(props) {
    super(props);
    this.state = {
      password:"",
      confirmPassword:"",
      newPassword:"",
      success:false,
      errors: {},
	  show:"none",
	  edit:false,
	  readonly:true,
	  tempPasswordTemp:"",
      tempPasswordNew:"",
	};
	this.resetState = this.resetState.bind(this);
	this.onCloseAlert = this.onCloseAlert.bind(this);
    this.onChange = this.onChange.bind(this);
	this.onSubmit = this.onSubmit.bind(this);
  }

  onCloseAlert() {
    this.setState({ success: false, edit: false, readonly: true, errors: {} });
    localStorage.removeItem("jwtToken");
    setJWTToken(false);
    window.location.href = "/auth/login";
  }

  async componentWillReceiveProps(nextProps) {
      this.setState({ errors: nextProps.errors });
  }

  componentDidMount() {
		const value = this.props.location.search
		var paramsArr = [];
		paramsArr = value.split('&temp');
		var code = paramsArr[0].substring(6);
		if(code != null) {
			this.props.validateCode(code, this.props.history);
		}
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  resetState() {
    this.setState({
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
      errors: {
        newPassword: "",
        oldPassword: "",
        confirmPassword: ""
      }
    });
  }
  onSubmit(e) {
    e.preventDefault();
    const value = this.props.location.search;
    var paramsArr = [];
    paramsArr = value.split("&temp");
    var code = paramsArr[0].substring(6);
    const User = {
      code: code,
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
      success: this.state.success,
      show: this.state.show,
    };
    this.props.resetPassword(User, this.props.history);
  }

  render() {
    const { isPasswordShown } = this.state;
    const { isPasswordShown1 } = this.state;
    const { t } = this.props;
    const { errors } = this.state;
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (

      <div className="changePasswordSection resetPasswordSection">
        <Grid container justify="center">
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={10} sm={8} style={{ height: "475px" }}>
            <div>
              <Grid container style={{ paddingTop: "15%" }}>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  style={{
                    borderBottom: "1px solid #0099FF",
                    boxShadow: "none !important",
                    textAlign: "left",
                    flexBasis: "100%",
                    maxWidth: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <label
                    style={{
                      font: "Montserrat,medium ",
                      fontSize: "20px",
                      marginRight: "5px",
                      color: "#003E67",
                    }}>
                    {t("resetPassword")}
                  </label>
                </Grid>
              </Grid>
            </div>

            <Card className="changePasswordBlock">
              <CardContent title="" className="changePasswordHeader">
                <Grid container justify="start"></Grid>
              </CardContent>
              <form onSubmit={this.onSubmit}>
                <CardContent className="changePasswordBody">
                  <br></br>
                  <Grid container>
                    <Grid item xs={12} sm={1} md={2} lg={2} xl={3} />
                    <Grid item xs={12} sm={10} md={8} lg={8} xl={6}>
                      <FormControl className="form-control">
                        <InputLabel required className="input-label">
                          {t("newPassword")}
                        </InputLabel>
                        <Input
                          required
                          placeholder="New Password"
                          className="text-field"
                          error={errors.newPassword ? true : false}
                          helperText={errors.newPassword}
                          InputLabelProps={{ shrink: false }}
                          autoComplete="off"
                          value={this.state.newPassword}
                          onChange={this.onChange}
                          name="newPassword"
                          id="newPassword"
                          type={isPasswordShown ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton className="password-icon"
                                tabindex="-1"
                                aria-label="toggle password visibility"
                                onClick={this.togglePasswordVisiblity}
                              >
                                {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          inputProps={{
                            pattern:
                              "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$",
                            title:
                              "At least One upper case one lower case one number and one special charcter. Minimum 8 maximum 30",
                          }}
                        />
                        <FormHelperText error={true}>{errors.newPassword}</FormHelperText>
                      </FormControl>
                      <FormControl className="form-control">
                        <InputLabel required className="input-label">
                          {t("confirmPassword")}
                        </InputLabel>

                        <Input
                          required
                          placeholder="Confirm Password"
                          className="text-field"
                          error={errors.confirmPassword ? true : false}
                          helperText={errors.confirmPassword}
                          InputLabelProps={{ shrink: false }}
                          autoComplete="off"
                          value={this.state.confirmPassword}
                          onChange={this.onChange}
                          name="confirmPassword"
                          id="confirmPassword"
                          type={isPasswordShown1 ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton className="password-icon"
                                tabindex="-1"
                                aria-label="toggle password visibility"
                                onClick={this.togglePasswordVisiblity1}
                              >
                                {isPasswordShown1 ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          inputProps={{
                            pattern:
                              "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$",
                            title:
                              "At least One upper case one lower case one number and one special charcter. Minimum 8 maximum 30",
                          }}
                        />
                        <FormHelperText error={true}>{errors.confirmPassword}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} md={3}></Grid> */}
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} sm={1} md={2} lg={2} xl={3} />
                    <Grid item xs={12} sm={10} md={8} lg={8} xl={6} >
                      <div className="my-btn-container">
                            <Button variant="contained" color="primary" type="submit" className="update-button">{t("update")}</Button>
                            <Button variant="contained" color="secondary" className="addApplicationBtn" component={ Link } onClick={this.resetState}>{t("cancel")}</Button>
                      </div>
                    </Grid>
                    </Grid>                 
                </CardContent>
              </form>
            </Card>
          </Grid>
          <Grid item xs={12} sm={2}>
            {this.state.success && Object.keys(errors).length === 0 && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={this.state.success}
                autoHideDuration={2000}
                onClose={this.onCloseAlert}
              >
                <Alert onClose={this.onCloseAlert} severity="success">
                  {t("updatedPasswordMessage")} <br></br>{t("loginWithNewPassword")}
                </Alert>
              </Snackbar>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

// export default ResetPassword;
ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  security: state.security,
  errors: state.errors,
});

export default compose(withTranslation(), connect(mapStateToProps, { validateCode, resetPassword }))(ResetPassword);