import axios from 'axios';
import { GET_ERRORS, GET_USER } from './types';
import { default as i18n } from 'i18next';

export const createNewUser = (newUser, history) => async (dispatch) => {
  try {
    const res = await axios.post('/v2/register', newUser);
    if (res.data.code == 'UASI0022') {
      history.push({
        pathname: '/auth/SuccessPage',
        state: { action: 'REGISTER_USER', message: i18n.t('loginActivationEmail'), link: '/auth/login', linkLabel: i18n.t('goToHomePage'), data: '' },
      });
    } else if (res.data.code == 'UASI0023') {
      history.push({
        pathname: '/auth/SuccessPage',
        state: { action: 'REGISTER_USER', message: i18n.t('loginActivationPhone'), link: '/auth/login', linkLabel: i18n.t('goToHomePage'), data: '' },
      });
    }
    dispatch({
      type: GET_ERRORS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data,
    });
  }
};

export const updateUser = (user, history) => async (dispatch) => {
  try {
    const res = await axios.put('/v2/update', user);
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });

    if (res.data.code === "UASI0002") {
      user.updateSuccess = true;
      dispatch({
        type: GET_USER,
        payload: user,
      });
    }
    dispatch({
      type: GET_ERRORS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data,
    });
  }
};

export const getUser = (id, history) => async (dispatch) => {
  try {
    const res = await axios.get(`/client/v1.0/user/${id}`);
    if (res.status === 200) {
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    }
    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (error) {}
};

export const changePassword = (user, history) => async (dispatch) => {
  try {
    const res = await axios.put('/client/v1.0/changePassword', user);
    // history.replace("/auth/changePassword" + user.id)
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
    if (res.status === 200) {
      user.success = true;
      user.show = 'none';
      dispatch({
        type: GET_USER,
        payload: user,
      });
    } else if (res.status === 401) {
      history.replace('/logout');
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data,
    });
  }
};
