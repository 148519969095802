import axios from 'axios';
import { GET_ERRORS, SET_CURRENT_USER } from './types';
import setJWTToken from '../securityUtils/setJWTToken';
import jwt_decode from 'jwt-decode';
import { default as i18n } from 'i18next';

export const login = (LoginRequest) => async (dispatch) => {
  try {
    // post => Login Request
    const res = await axios.post('/client/v1.0/login', LoginRequest);
    // extract token from res.data
    const token = res.data.data;
    // const { token } = res.data;
    // store the token in the localStorage
    localStorage.setItem('jwtToken', token);
    // set our token in header ***
    setJWTToken(token);
    // decode token on React
    const decoded = jwt_decode(token);
    // dispatch to our securityReducer
    dispatch({
      type: SET_CURRENT_USER,
      payload: decoded,
    });
  } catch (err) {
    if (err.response.status === 401) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    } else {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.data,
      });
    }
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('jwtToken');
  setJWTToken(false);
  dispatch({
    type: SET_CURRENT_USER,
    payload: {},
  });
};

export const emailValidate = (email, history) => async (dispatch) => {
  try {
    const res = await axios.post('/client/v1.0/forgotPassword?email=' + email);
    if (res.status === 200) {
      history.push({
        pathname: '/auth/SuccessPage',
        state: { message: i18n.t('loginToResetPassword'), link: '/auth/login', linkLabel: i18n.t('goToHomePage') },
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data.data,
    });
  }
};

export const validateCode = (code, history) => async (dispatch) => {
  try {
    const res = await axios.post('/client/v1.0/validateResetPasswordLink?code=' + code);
    if (res.status === 200) {
      history.replace('/auth/resetPassword?code=' + code);
    }
  } catch (error) {
    history.push({
      pathname: '/auth/SuccessPage',
      state: { message: i18n.t('linkExpired'), link: '/auth/login', linkLabel: i18n.t('goToHomePage') },
    });
  }
};

export const resetPassword = (user, history) => async (dispatch) => {
  try {
    const res = await axios.put('/client/v1.0/resetPassword', user);
    if (res.status === 200) {
      history.push({
        pathname: '/auth/SuccessPage',
        state: { action: 'RESET_PASSWORD', message: i18n.t('passwordResetSuccess'), link: '/auth/login', linkLabel: i18n.t('goToHomePage') },
      });
    }
  } catch (error) {
    if (error.response.status === 404) {
      history.push({
        pathname: '/auth/SuccessPage',
        state: { action: 'RESET_PASSWORD', message: i18n.t('linkExp'), link: '/auth/login', linkLabel: i18n.t('goToHomePage') },
      });
    } else if (error.response.status === 400) {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data.data,
      });
    }
  }
};

export const validateAccount = (id, key, history) => async (dispatch) => {
  try {
    const res = await axios.put('/client/v2/activateAccount?id=' + id + '&key=' + key);
    if (res.status === 200) {
      if (res.data.code === 'UASI0017') {
        history.push({
          pathname: '/auth/SuccessPage',
          state: { action: 'ACCOUNT_ACTIVATE', message: i18n.t('accountActivated'), link: '/auth/login', linkLabel: i18n.t('goToHomePage'), data: '' },
        });
      } else if (res.data.code === 'UASE0046') {
        history.push({
          pathname: '/auth/SuccessPage',
          state: { action: 'ACCOUNT_REACTIVATE', message: i18n.t('resendLinkText'), link: '/auth/login', linkLabel: i18n.t('resendLinkbutton'), data: res.data.data },
        });
      }
    }
  } catch (error) {
    console.log('error=====', error);
    history.push({
      pathname: '/auth/SuccessPage',
      state: { action: 'ACCOUNT_ACTIVATE', message: i18n.t('linkExp'), link: '/auth/login', linkLabel: i18n.t('goToHomePage') },
    });
  }
};

export const resendActivationLink = (email, history) => async (dispatch) => {
  try {
    const res = await axios.post('/client/v1.0/resendActivationLink?email=' + email);
    if (res.status === 200) {
      history.push({
        pathname: '/auth/SuccessPage',
        state: { message: i18n.t('linkSentSuccess'), link: '/auth/login', linkLabel: i18n.t('goToHomePage') },
      });
    }
  } catch (error) {
    history.push({
      pathname: '/auth/SuccessPage',
      state: { message: 'Error', link: '/auth/login', linkLabel: i18n.t('goToHomePage') },
    });
  }
};
