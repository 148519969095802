import React, { Component } from 'react';
import "./ComingSoon.css";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class ComingSoon extends Component {
	render() {
		 const { t } = this.props;
		return (
			<div id="notfound">
				<div class="notfound">
					<h2>{t("comingSoon")}</h2>
					<Link to="/">{t("homePageNavigate")}</Link>
				</div>
			</div>
		);
	}
}


export default withTranslation()(ComingSoon);
