import { Avatar, Button, Card, CardActionArea, CardContent, CardHeader, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { default as i18n } from 'i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { createNewUser } from '../../actions/UserActions';
import codeLogo from '../../images/code.png';
import leftBanner from '../../images/leftBanner.svg';

class Register extends Component {
  state = {
    isPasswordShown: false,
    isPasswordShown1: false,
    isSubmitting: false,
  };

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };
  togglePasswordVisiblity1 = () => {
    const { isPasswordShown1 } = this.state;
    this.setState({ isPasswordShown1: !isPasswordShown1 });
  };

  constructor() {
    super();

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      errors: {},
      isError: false,
      autoPassword: '',
      isAutoGeneratedPassword: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.generateP = this.generateP.bind(this);
    this.getSelectedItem = this.getSelectedItem.bind(this);
    this.generatePassword = this.generatePassword.bind(this);
    this.shuffleArray = this.shuffleArray.bind(this);
  }

  componentDidMount() {
    console.log(this.props.location);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors, isSubmitting: false});
    }
  }

  generatePassword(passwordLength) {
    var numberChars = '0123456789';
    var upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var lowerChars = 'abcdefghiklmnopqrstuvwxyz';
    var symbols = '!@#$%&*';
    var allChars = numberChars + upperChars + lowerChars + symbols;
    var randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = symbols;
    randPasswordArray = randPasswordArray.fill(allChars, 4);
    return this.shuffleArray(
      randPasswordArray.map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
    ).join('');
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  generateP() {
    this.setState({ autoPassword: this.generatePassword(15) });
  }

  getSelectedItem(val) {
    this.setState({ confirmPassword: val, password: val });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitting: true }); 
    if (this.state.confirmPassword !== this.state.password) {
      this.setState({ isError: true , isSubmitting: false});
    } else {
      const newUser = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      };

      this.props.createNewUser(newUser, this.props.history)
      .finally(() => this.setState({ isSubmitting: false }));
    }
  }

  onChange(e) {
    if (e.target.name == 'confirmPassword' || e.target.name == 'password') {
      this.setState({ isError: false });

    }
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const top100Films = [{ code: 'Use a securely Generate Password', title: this.state.autoPassword, year: 1994 }];

    const { isPasswordShown } = this.state;
    const { isPasswordShown1 } = this.state;
    const { errors } = this.state;
    let { t } = this.props;
    return (
      <div classname='registration-form'>
        <Grid container>
          <Grid item sm={12} md={6} lg={8} xl={9} className='pt-60'>
            <div className='login-component' style={{ backgroundImage: `url(${leftBanner})` }}>
              <Grid container>
                <Grid item lg={1}></Grid>
                <Grid item md={12} sm={12} lg={11}>
                  <Card className='registration-card'>
                    <CardContent>
                      <Typography id='minervaiot' className='registration-card-header' gutterBottom>
                        {t('minervaIot')}
                      </Typography>
                      <Typography id='authorization-server' variant='h5' component='h2' className='registerStyleCardSubHeader'>
                        {t('authorizationServer')}
                      </Typography>
                      <Typography id='oauth2-server-authorization' className='registration-card-body'>{t('OAuth2ServerAuthorization')}</Typography>

                      <Avatar id='desktop-icon' size={100} className='registration-page-avatar'>
                        <img src={codeLogo} alt='Code' />
                      </Avatar>
                      <hr className='hr-border'></hr>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} className='pt-60'>
            {/* ================ */}

            <Grid container justify='center' className='register-form-area'>
              <Grid item xs={12} sm={12}>
                <Card className='card-container'>
                  <CardHeader id='user' className='card-header-1' titleTypographyProps={{ variant: 'subtitle1' }} title={t('userRegister')} />
                  <CardHeader id='sign-up' className='card-header-2' titleTypographyProps={{ variant: 'subtitle2' }} title={t('signUp')} />
                  <form onSubmit={this.onSubmit}>
                    <CardContent>
                      <FormControl className='login-form-control'>
                        <Grid container spacing={1} className='login-field-container'>
                          <Grid item xs={1}>
                            <PersonIcon className='form-icon form-icon-fill' />
                          </Grid>
                          <Grid item xs={11}>
                            <TextField
                              p={2}
                              className='input-filed'
                              type='text'
                              label={t('firstName')}
                              placeholder={t('firstName')}
                              error={errors.firstName ? true : false}
                              required
                              helperText={errors.firstName}
                              autoComplete='off'
                              value={this.state.firstName}
                              onChange={this.onChange}
                              name='firstName'
                              id='firstName'
                              inputProps={{
                                pattern: '[a-zA-Z ]{2,30}',
                                title: i18n.t('firstAndLastName'),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl className='login-form-control'>
                        <Grid container spacing={1} className='login-field-container'>
                          <Grid item xs={1}>
                            <PersonIcon className='form-icon form-icon-fill' />
                          </Grid>
                          <Grid item xs={11}>
                            <TextField
                              p={2}
                              className='input-filed'
                              type='text'
                              label={t('lastName')}
                              placeholder={t('lastName')}
                              error={errors.lastName ? true : false}
                              helperText={errors.lastName}
                              autoComplete='off'
                              value={this.state.lastName}
                              onChange={this.onChange}
                              name='lastName'
                              id='lastName'
                              inputProps={{
                                pattern: '[a-zA-Z ]{2,30}',
                                title: i18n.t('firstAndLastName'),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl className='login-form-control'>
                        <Grid container spacing={1} className='login-field-container'>
                          <Grid item xs={1}>
                            <EmailIcon className='form-icon form-icon-fill' />
                          </Grid>
                          <Grid item xs={11}>
                            <TextField
                              p={2}
                              type='email'
                              label={t('email')}
                              placeholder={t('email')}
                              className='input-filed'
                              error={errors.data?.email ? true : false}
                              helperText={errors.data?.email ? errors.message : ''}
                              autoComplete='off'
                              required
                              value={this.state.email}
                              onChange={this.onChange}
                              name='email'
                              id='email'
                              inputProps={{
                                pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$',
                                title: i18n.t('validEmailAddress'),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl className='login-form-control'>
                        <Grid container spacing={1} className='login-field-container'>
                          <Grid item xs={1}>
                            <PhoneIcon className='form-icon form-icon-fill' />
                          </Grid>
                          <Grid item xs={11}>
                            <TextField
                              p={2}
                              className='input-filed'
                              type='text'
                              label={t('contact')}
                              placeholder={t('contact')}
                              inputProps={{
                                pattern: '/^(\+\d{1,3}[- ]?)?\d{8,15}$/',
                                title: i18n.t('contactNumberValid'),
                              }}
                              autoComplete='off'
                              error={errors.data?.phone ? true : false}
                              helperText={errors.data?.phone ? errors.message : ''}
                              value={this.state.phone}
                              onChange={this.onChange}
                              name='phone'
                              id='phone'
                            />
                          </Grid>
                        </Grid>
                      </FormControl>

                      <FormControl className='login-form-control'>
                        <Grid container spacing={1} className='login-field-container'>
                          <Grid item xs={1}>
                            <LockIcon className='form-icon form-icon-fill' />
                          </Grid>
                          <Grid item xs={11}>
                            <div>
                              <div className='passwordContainer' style={{ float: 'right', position: 'relative', top: '25px', right: '-11px', zIndex: '999' }} >
                                <InputAdornment position='end'>
                                  <IconButton id='password_icon' className='password-icon' tabindex='-1' aria-label='toggle password visibility' onClick={this.togglePasswordVisiblity}>
                                    {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              </div>
                              <div>
                                <Autocomplete
                                  id='virtualize-demo'
                                  freeSolo
                                  options={top100Films}
                                  autoHighlight
                                  getOptionLabel={(option) => option.title}
                                  onChange={(event, newValue) => {
                                    if (newValue != null) this.getSelectedItem(newValue.title);
                                  }}
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      <span>{option.title}</span>
                                    </React.Fragment>
                                  )}
                                  groupBy={(option) => (
                                    <React.Fragment>
                                      <span style={{ fontWeight: 'bold', color: 'black' }}>{option.code}</span>
                                    </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      className='passwordInputFiled'
                                      {...params}
                                      error={errors.password ? true : false}
                                      helperText={errors.password} autoComplete='off'
                                      value={this.state.password}
                                      onChange={this.onChange}
                                      name='password'
                                      id='password'
                                      label={t('password')}
                                      placeholder=' '
                                      required
                                      type={isPasswordShown ? 'text' : 'password'}
                                      margin='normal'
                                      onClick={this.generateP}
                                      inputProps={{
                                        ...params.inputProps,
                                        pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$',
                                        title: i18n.t('passwordValidNotification'),
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl className='login-form-control'>
                        <Grid container spacing={1} className='login-field-container'>
                          <Grid item xs={1}>
                            <LockIcon className='form-icon form-icon-fill' />
                          </Grid>
                          <Grid item xs={11}>
                            <TextField
                              p={2}
                              className='input-filed'
                              label={t('confirmPassword')}
                              placeholder={t('confirmPassword')}
                              helperText={this.state.isError ? "Password does not match" : ""}
                              error={this.state.isError ? true : false}
                              autoComplete='off'
                              value={this.state.confirmPassword}
                              onChange={this.onChange}
                              required
                              name='confirmPassword'
                              id='confirmPassword'
                              type={isPasswordShown1 ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton id='conformPassword_icon' className='password-icon' tabIndex='-1' aria-label='toggle password visibility' onClick={this.togglePasswordVisiblity1}>
                                      {isPasswordShown1 ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{
                                pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$',
                                title: i18n.t('passwordValidNotification'),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                      <br />
                      <br />

                      <Grid container spacing={5} justify='center'>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div id='sign-in' className='signin-text'>
                            {t('accountExists')}{' '}
                            <Link className='link' to='/'>
                              {t('signIn')}
                            </Link>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Button id='sign-up' className='login-button' variant='contained' type='submit' disabled={this.state.isSubmitting} >
                            {' '}
                            {t('signUp')}{' '}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </form>
                  <CardActionArea></CardActionArea>
                </Card>
              </Grid>
            </Grid>

            {/* ====================== */}
          </Grid>
        </Grid>
      </div>
    );
  }
}

Register.propTypes = {
  createNewUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  security: state.security,
});
export default compose(withTranslation(), connect(mapStateToProps, { createNewUser }))(Register);
