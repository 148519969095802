import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { emailValidate } from '../../actions/securityActions';
import { Grid, Card, CardHeader, CardActionArea, CardContent, Button, Typography, FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { default as i18n } from 'i18next';

class EmailVerification extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onSubmit(e) {
    e.preventDefault();
    this.props.emailValidate(this.state.email, this.props.history);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  render() {
    const card = {
      borderRight: 'solid',
      borderLeft: 'solid',
      borderBottom: 'solid',
      borderWidth: '1px',
      borderColor: '#0098FF',
      boxShadow: '0px 3px 3px #00000066',
      margin: '10%',
    };
    const cardHeader1 = {
      padding: '0px',
      background: '#AAE5FF',
      font: 'normal normal small 15px/18px Lato',
      fontSize: 'small',
      letterSpacing: '0px',
      color: '#000000',
      height: '100%',
      width: '100%',
      textAlign: 'center',
      borderRadius: '100%',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      textTransform: 'uppercase',
    };
    const cardContent = {};

    const button = {
      backgroundColor: '#ECF9FF',
      border: 'solid',
      borderColor: '#0098FF5C',
      borderWidth: '1px',
      width: '30%',
    };
    const { t } = this.props;
    return (
      <div>
        <div classname='registration-form'>
          <Grid container>
            <Grid item sm={12} md={6} lg={8} xl={9} className='pt-60'>
              <div className='login-component'>
                <Grid container>
                  <Grid lg={1}></Grid>
                  <Grid item md={12} sm={12} lg={11}>
                    <Card className='login-card'>
                      <CardContent className='textAlignCenter'>
                        <Typography id='minervaiot' className='login-card-header' gutterBottom>
                          {t('minervaIot')}
                        </Typography>
                        <Typography className='login-card-body'>{t('authorizationTitle')}</Typography>
                        <hr className='hr-border'></hr>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} className='form-area pt-60'>
              <Grid container justify='center'>
                <Grid item xs={12} sm={12}>
                  <Card style={card}>
                    <CardHeader id='reset' style={cardHeader1} titleTypographyProps={{ variant: 'subtitle1' }} title={t('reset')} />
                    <form onSubmit={this.onSubmit}>
                      <CardContent style={cardContent}>
                        <FormControl className='login-form-control'>
                          <Grid container spacing={1} className='login-field-container'>
                            <Grid item xs={1}>
                              <EmailIcon className='form-icon form-icon-fill' />
                            </Grid>
                            <Grid item xs={11}>
                              <TextField
                                p={2}
                                type='email'
                                label={t('registeredEmail')}
                                placeholder={t('registeredEmail')}
                                className='input-filed mb-25'
                                error={this.state.errors.email ? true : false}
                                helperText={this.state.errors.email}
                                autoComplete='off'
                                value={this.state.email}
                                onChange={this.onChange}
                                onClick={() => {
                                  this.setState({
                                    error: {
                                      email: '',
                                    },
                                  });
                                }}
                                name='email'
                                id='email'
                                inputProps={{ pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$', title: i18n.t('validEmailAddress') }}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <br />
                        <Grid container spacing={1} justify='center'>
                          <Button id='submit-button' style={button} variant='contained' type='submit'>
                            {' '}
                            {t('submit')}{' '}
                          </Button>
                        </Grid>
                        <br />
                        <br />
                      </CardContent>
                    </form>
                    <CardActionArea></CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
EmailVerification.propTypes = {
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  security: state.security,
  errors: state.errors,
});
export default compose(withTranslation(), connect(mapStateToProps, { emailValidate }))(EmailVerification);
