import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import { resendActivationLink } from "../../actions/securityActions";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class SuccessPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: "",
			errors: {}
		};
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(e) {
		e.preventDefault();
		const { data } = this.props.location.state;
		let emailId;
		if ((data !== undefined && data !== '')) {
			emailId = data;
		} else {
			emailId = "";
		}
		this.props.resendActivationLink(emailId, this.props.history);
	}

	render() {
		const { message, link, linkLabel, action } = this.props.location.state;

		let success = '';
		if (action === "ACCOUNT_REACTIVATE") {
			success = <div id="successPage">
				<div className="successPage">
					<div className="successmsg">
						<span className="message">
							<h3>{message}</h3>
						</span>
					</div>
					<form onSubmit={this.onSubmit}>
						<Button variant="contained" color="secondary" type="submit">
							{linkLabel}
						</Button>
					</form>
				</div>
			</div>
		} else {
			success = <div id="successPage">
				<div className="successPage">
					<div className="successmsg">
						<span className="message">
							<h3>{message}</h3>
						</span>
					</div>
					<div className="successLink">
						<Link to={link}>{linkLabel}</Link>
					</div>
				</div>
			</div>
		}
		return (
			<span>
				{success}
			</span>
		)
	}
}

SuccessPage.propTypes = {
	errors: PropTypes.object.isRequired,
	security: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	security: state.security,
	errors: state.errors
});
export default compose(withTranslation(), connect(
	mapStateToProps,
	{ resendActivationLink }
))(SuccessPage);