import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import securityReducer from "./securityReducer";
import UserReducer from "./UserReducer";

export default combineReducers({
  errors: errorReducer,
  security: securityReducer,
  user: UserReducer
});
