import { Avatar, Button, Menu, MenuItem } from "@material-ui/core";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import MinervaIOTLogo from '../../images/minerva-iot-logo.png';
import setJWTToken from "../../securityUtils/setJWTToken";

const handleOnclickLogout = () => {
  localStorage.removeItem("jwtToken");
  setJWTToken(false);
  window.location.href = "/";
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginBottom: '10px',
    '& > span': {
      maxWidth: 15,
      width: '100%',
      backgroundColor: 'white',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    border: 'none',
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
      outline: 'none',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(0.5),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#0099FF',
  },
}));

export default function HeaderLinks(props) {
  const classes = useStyles();
  const align = props.align;
  const [value, setValue] = React.useState(0);
  const user = props.user;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMobileDrawer = () => {
    props.handleDrawerToggle();
    setAnchorEl(null);

  };

  const handleClose = () => {
    setAnchorEl(null);

  };
  const { t } = useTranslation();
  return (
    <div className={classes.root}>

      <div className={classes.demo2}>
        <NavLink id='profile-pic' to="/" className="drawer-logo-container" onClick={closeMobileDrawer} style={{ textDecoration: 'none', color: 'unset' }} >
          <img src={MinervaIOTLogo} className="drawer-logo" alt="Logo" />
        </NavLink >
        <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example" orientation={align}>

          <StyledTab className="profileTab" label={<Avatar id='profile-pic' size={100} className="profileAvatar" >{user.firstName[0]}</Avatar>}
            onClick={handleClick} />
          <Menu
            id="simple-menu2"
            anchorEl={anchorEl}
            keepMounted
            className="my-secure-dropdown-menu"
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ zIndex: "999999" }}
          >
            <MenuItem id='profile' onClick={closeMobileDrawer}
              to={{
                pathname: `/auth/UserProfile/${user.id}`,
                devId: user.id
              }} component={Link}>{t("profile")}</MenuItem>
            <MenuItem id='change-password' onClick={closeMobileDrawer}
              to={{
                pathname: `/auth/changePassword/${user.id}`,
                devId: user.id
              }} component={Link}>{t("changePassword")}</MenuItem>
          </Menu>
          <Button id='sign-out-button' className="headerLoginBtn" variant="link" onClick={handleOnclickLogout} to={{ pathname: "/dev/login" }} style={{ marginRight: "2%", color: "inherit", textDecoration: "none" }}>
            {t("signOut")}
          </Button>
        </StyledTabs>
        <Typography className={classes.padding} />
      </div>
    </div>
  );
}
