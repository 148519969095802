import React, { Component } from 'react';
import "../../PageNotFound.css";
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";

class PageNotFound extends Component {
	render() {
		const { t } = this.props;
		return (
			<div id="notfound">
				<div class="notfound">
					<div class="notfound-404">
						<h1>{t("404")}</h1>
					</div>
					<h2>{t("pageNotFound")}</h2>
					<p>{t("pageTemporarliyUnavailable")}</p>
					<Link to="/">{t("homePageNavigate")}</Link>
				</div>
			</div>
		);
	}
}

export default withTranslation()(PageNotFound);