import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/icons/Menu";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { logout } from "../../actions/securityActions";
import MinervaIOTLogo from '../../images/Minerva_LogoColor.svg';
import HeaderLinks from "./HeaderLinks";
import SecureHeaderLinks from "./SecureHeaderLinks";
import styles from "./headerStyle";

const useStyles = makeStyles(styles);

function Header(props) {
  const { validToken, user } = props.security;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { color, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });

  let rightLinks = <HeaderLinks handleDrawerToggle={handleDrawerToggle} align="" user={user}></HeaderLinks>
  let rightVerticalLinks = <HeaderLinks handleDrawerToggle={handleDrawerToggle} align="vertical" user={user}></HeaderLinks>
  let rightSecureLinks = <SecureHeaderLinks handleDrawerToggle={handleDrawerToggle} align="" user={user}></SecureHeaderLinks>
  let rightVerticalSecureLinks = <SecureHeaderLinks handleDrawerToggle={handleDrawerToggle} align="vertical" user={user}></SecureHeaderLinks>
  let HeaderBody = "";

  let unsecureHeader = (
    <AppBar className={appBarClasses} style={{ position: 'fixed', zIndex: "99999", backgroundColor: "#0099FF", color: 'white', borderRadius: "0px", padding: "0px", marginBottom: "0px", boxShadow: "none" }} >
      <div className={classes.flex} style={{ paddingLeft: "2%", textAlign: "left" }}>
        <NavLink to="/" style={{ textDecoration: 'none', color: 'unset' }} >
          <span id='header-image'>
            <img src={MinervaIOTLogo} className="header-logo" alt="Logo" />
          </span>
        </NavLink >

      </div>
      <Toolbar >
        <Hidden smDown implementation="css" >
          {rightLinks}
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {rightVerticalLinks}

          </div>

        </Drawer>
      </Hidden>
    </AppBar>
  );

  let SecureHeader = (
    <AppBar className={appBarClasses} style={{ backgroundColor: "#0099FF", color: 'white', borderRadius: "0px", padding: "0px", marginBottom: "0px", height: "60px" }} >

      <div className={classes.flex} style={{ paddingLeft: "2%", textAlign: "left" }}>
        <NavLink to="/" style={{ textDecoration: 'none', color: 'unset' }} >
          <span id="header-image">
          <img src={MinervaIOTLogo} className="header-logo" alt="Logo" />
          </span>
        </NavLink >
      </div>
      <Toolbar>
        <Hidden smDown implementation="css">
          {rightSecureLinks}
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js" >
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
          style={{ zIndex: "99999" }}
        >
          <div className={classes.appResponsive}>
            {rightVerticalSecureLinks}
          </div>
        </Drawer>
      </Hidden>
      {/* <Link className="nav-link" to={`/auth/UserProfile/${user.id}`} style={{ marginRight: "2%", color: "inherit", textDecoration: "none" }}>
        <Avatar style={{ backgroundColor: "#" + Math.floor(Math.random() * 16777215).toString(16) }}>{user.name !== undefined ? user.name[0] : ''}</Avatar>
      </Link>


      <Link className="nav-link" to="/logout" onClick={logout} style={{ marginRight: "2%", color: "inherit", textDecoration: "none" }}> Logout </Link>
     */}
    </AppBar>
  );
  if (validToken && user) {
    HeaderBody = SecureHeader;
  } else {
    HeaderBody = unsecureHeader;
  }
  return <div >
    {HeaderBody}
  </div>
}

Header.propTypes = {
  fixed: true,
  absolute: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  security: state.security
});

export default connect(
  mapStateToProps,
  { logout }
)(Header);
