import { Avatar, Button, Card, CardContent, FormControl, Grid, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import { default as i18n } from 'i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getUser, updateUser } from '../../actions/UserActions';

class ProfilePage extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      tempFirstName: '',
      tempLastName: '',
      tempEmail: '',
      tempPhone: '',
      errors: {},
      enableEdit: false,
      enableDelete: false,
      readonly: true,
      updateSuccess: false,
      deleteSuccess: false,
      userInitial: '',
    };
    this.resetState = this.resetState.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleEnableEdit = this.handleEnableEdit.bind(this);
    this.handleEnableDelete = this.handleEnableDelete.bind(this);
    this.handleCloseUpdateAlert = this.handleCloseUpdateAlert.bind(this);
    this.handleDeleteDialogOpen = this.handleDeleteDialogOpen.bind(this);
    this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this);
  }
  handleEnableEdit() {
    this.setState({
      tempFirstName: this.state.firstName,
      tempLastName: this.state.lastName,
      tempEmail: this.state.email,
      tempPhone: this.state.phone,
      enableEdit: !this.state.enableEdit,
      readonly: false,
      enableDelete: false,
      updateSuccess: false,
    });
  }
  resetState() {
    this.setState({
      firstName: this.state.tempFirstName,
      lastName: this.state.tempLastName,
      email: this.state.tempEmail,
      phone: this.state.tempPhone,
      enableEdit: false,
      readonly: true,
      enableDelete: false,
      updateSuccess: false,
      errors: {},
    });
  }
  handleEnableDelete() {
    this.setState({
      tempFirstName: this.state.firstName,
      tempLastName: this.state.lastName,
      tempEmail: this.state.email,
      tempPhone: this.state.phone,
      enableEdit: false,
      enableDelete: !this.state.enableDelete,
      readonly: true,
    });
  }

  handleCloseUpdateAlert() {
    this.setState({ updateSuccess: false, enableEdit: false, readonly: true, errors: {} });
  }

  handleDeleteDialogOpen() {
    this.setState({ open: true });
  }

  handleDeleteDialogClose() {
    this.setState({ open: false });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors.code === 'UASI0002') {
      this.setState({
        updateSuccess: true,
        errors: nextProps.errors
      });
    } else {
      this.setState({
        updateSuccess: false,
        errors: nextProps.errors
      });
    } if (nextProps.user.firstName !== undefined && nextProps.user.firstName !== '') {
      this.setState({ userInitial: nextProps.user.firstName[0] });
    }
    const { id, firstName, lastName, phone, email, updateSuccess, deleteSuccess } = nextProps.user;
    this.setState({
      id,
      firstName,
      lastName,
      phone,
      email,
      updateSuccess,
      deleteSuccess,
    });
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getUser(id, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const updateUser = {
      id: this.state.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      updateSuccess: this.state.updateSuccess,
      deleteSuccess: this.state.deleteSuccess,
    };
    this.props.updateUser(updateUser, this.props.history);
  }

  async remove(id) {
    await axios
      .delete(`/client/v1.0/user/${id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        localStorage.removeItem('jwtToken');
        window.location.href = '/';
        this.setState({ deleteSuccess: true });
      });
  }

  render() {
    const { errors } = this.state;
    const { t } = this.props;
    function Alert(props) {
      return <MuiAlert elevation={6} variant='filled' {...props} />;
    }
    return (
      <div className='profileSection'>
        <Grid container justify='center'>
          <Grid item xs={10} sm={8} style={{ height: '475px' }}>
            <div>
              <Grid
                container
                style={{
                  paddingTop: '7%',
                  borderBottom: '1px solid #0099FF',
                  boxShadow: 'none !important',
                  textAlign: 'left',
                  flexBasis: '100%',
                  maxWidth: '100%',
                  marginBottom: '20px',
                }}
              >
                <Grid container item xs={8} direction='row' justify='flex-start' alignItems='center'>
                  <label id={"my-profile"} style={{ font: 'Montserrat,medium ', fontSize: '20px', marginRight: '5px', color: '#003E67' }}>{t('myProfile')}</label>
                </Grid>
                <Grid container item xs={4} direction='row' justify='flex-end' alignItems='center'>
                  <IconButton id='edit-icon' color='primary' aria-label='edit profile' onClick={this.handleEnableEdit} style={{ display: this.state.enableEdit ? 'none' : '' }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton id='delete-icon' color='secondary' aria-label='delete profile' onClick={this.handleEnableDelete} style={{ display: this.state.enableDelete ? 'none' : '' }}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <Card className='profileBlock'>
              <CardContent className='profileHeader' title=''>
                <Grid container justify='start'>
                  <Grid item xs={1} sm={12}>
                    <Avatar id='profile-pic' size={100} className='profile-page-avatar'>
                      {this.state.userInitial}
                    </Avatar>
                  </Grid>
                </Grid>
              </CardContent>
              <form onSubmit={this.onSubmit}>
                <CardContent className='profileBody'>
                  <Grid container justify='center'>
                    <Grid item xs={12} sm={2}></Grid>
                    <Grid item xs={12} sm={5} >
                      <FormControl className='login-form-control form-control'>
                        <TextField
                          p={2}
                          type='text'
                          className='text-field'
                          placeholder={t('firstName')}
                          label={t('firstName')}
                          error={errors.firstName ? true : false}
                          helperText={errors.firstName}
                          autoComplete='off'
                          value={this.state.firstName}
                          disabled={this.state.enableEdit === false}
                          onChange={this.onChange}
                          name='firstName'
                          id='firstName'
                          inputProps={{ pattern: '[a-zA-Z ]{2,30}', title: 'Characters only of length 2 to 30', readOnly: this.state.readonly }}
                        />
                      </FormControl>
                      <FormControl className='login-form-control form-control'>
                        <TextField
                          p={2}
                          type='text'
                          className='text-field'
                          placeholder={t('phone')}
                          label={t('phone')}
                          inputProps={{
                            pattern: '[+][0-9]{2,}[-][0-9]{8,15}$',
                            title: i18n.t('contactNumberValid'),
                          }}
                          disabled={this.state.enableEdit === false}
                          autoComplete='off'
                          error={errors.data?.phone ? true : false}
                          helperText={errors.data?.phone ? errors.message : ''}
                          value={this.state.phone}
                          onChange={this.onChange}
                          name='phone'
                          id='phone'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormControl className='login-form-control form-control'>
                        <TextField
                          p={2}
                          type='text'
                          className='text-field'
                          placeholder={t('lastName')}
                          label={t('lastName')}
                          error={errors.lastName ? true : false}
                          helperText={errors.lastName}
                          autoComplete='off'
                          value={this.state.lastName}
                          disabled={this.state.enableEdit === false}
                          onChange={this.onChange}
                          name='lastName'
                          id='lastName'
                          inputProps={{ pattern: '[a-zA-Z ]{2,30}', title: 'Characters only of length 2 to 30', readOnly: this.state.readonly }}
                        />
                      </FormControl>
                      <FormControl className='login-form-control form-control'>
                        <TextField
                          p={2}
                          type='text'
                          className='text-field'
                          placeholder={t('email')}
                          label={t('email')}
                          error={errors.email ? true : false}
                          helperText={errors.email}
                          autoComplete='off'
                          value={this.state.email}
                          onChange={this.onChange}
                          name='email'
                          id='email'
                          disabled={this.state.enableEdit === false}
                          inputProps={{ readOnly: this.state.readonly }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container justify='center' style={{ display: !this.state.enableEdit ? 'none' : '' }}>
                    <Grid item xs={12} sm={2}></Grid>
                    <Grid item xs={12} sm={10}>
                      <div className='my-btn-container'>
                        <Button id='update-button' variant='contained' color='primary' type='submit' className='update-button'>
                          {t('update')}
                        </Button>
                        <Button id='cancel-button' variant='contained' className='cancelBtn' onClick={this.resetState}>
                          {t('cancel')}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container justify='center' style={{ display: !this.state.enableDelete ? 'none' : '' }}>
                    <Grid item xs={12} sm={2}></Grid>
                    <Grid item xs={12} sm={10}>
                      <div className='my-btn-container'>
                        <Button variant='contained' color='secondary' onClick={this.handleDeleteDialogOpen} id='delete'>
                          {t('delete')}
                        </Button>
                        <Button variant='contained' className='cancelBtn' onClick={this.resetState} id='cancle'>
                          {t('cancel')}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Grid>
        </Grid>
        {/* pop up for success message */}
        <Grid item xs={12} sm={2} style={{ display: !this.state.enableEdit ? 'none' : '' }}>
          {errors.code === "UASI0002" && (
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.updateSuccess} autoHideDuration={2000} onClose={this.handleCloseUpdateAlert}>
              <Alert onClose={this.handleCloseUpdateAlert} severity='success'>
                {t('updatedProfileMessage')}
              </Alert>
            </Snackbar>
          )}
          {errors.code !== "UASI0002" && Object.keys(errors).length !== 0 && (
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={true} autoHideDuration={2000} onClose={this.handleCloseUpdateAlert}>
              <Alert onClose={this.handleCloseUpdateAlert} severity='error'>
                {errors.message}
              </Alert>
            </Snackbar>
          )}
        </Grid>
        {/* pop up for delete success message */}
        <Grid item xs={12} sm={2}>
          {this.state.deleteSuccess && (
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.deleteSuccess} autoHideDuration={50000}>
              <Alert severity='success'>{t('profileDeletedMessage')}</Alert>
            </Snackbar>
          )}
        </Grid>
        {/* Dialog for delete */}
        <Dialog
          style={{ textAlign: 'center' }}
          open={this.state.open}
          onClose={this.handleDeleteDialogClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{t('confirm')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <p>{t('deleteYourAccount')}</p>
            </DialogContentText>
          </DialogContent>
          <div style={{ marginRight: '25%' }}>
            <DialogActions>
              <Button id='no-button' onClick={this.handleDeleteDialogClose} variant='contained' color='primary'>
                {t('no')}
              </Button>
              <Button
                id='yes-button'
                variant='contained'
                color='secondary'
                onClick={() => {
                  this.remove(this.state.id);
                }}
              >
                {t('yes')}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}
// export default ProfilePage;
ProfilePage.propTypes = {
  getUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  errors: state.errors,
});

export default compose(withTranslation(), connect(mapStateToProps, { getUser, updateUser }))(ProfilePage);
