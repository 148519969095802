import { Button, Card, CardActionArea, CardContent, CardHeader, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { default as i18n } from 'i18next';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { login } from "../../actions/securityActions";

class Login extends Component {
  state = {
    isPasswordShown: false,
  };


  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},


    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.security.validToken) {
      this.props.history.push(
        "/auth/UserProfile/" + this.props.security.user.id
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.security.validToken) {
      this.props.history.push(
        "/auth/UserProfile/" + nextProps.security.user.id
      );
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const LoginRequest = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.login(LoginRequest);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });


  }

  render() {
    const { isPasswordShown } = this.state;
    const { errors } = this.state;
    const { t } = this.props;

    return (
      <div classname="registration-form">
        <Grid container>
          <Grid item sm={12} md={6} lg={8} xl={9} className="pt-60">
            <div className="login-component">
              <Grid container>
                <Grid item lg={1}></Grid>
                <Grid item md={12} sm={12} lg={11}>
                  <Card className="login-card">
                    <CardContent>
                      <Typography id='Minerva Iot' className="login-card-header" gutterBottom>
                        {t("minervaIot")}
                      </Typography>
                      <Typography id='Iot Platform' className="login-card-body">
                        {t("authorizationTitle")}
                      </Typography>
                      <hr className="hr-border"></hr>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
            className="form-area pt-60"
          >
            {/* ================ */}

            <Grid container justify="center">
              <Grid item xs={12} sm={12}>
                <Card className="card-container">
                  <CardHeader
                    id="sign-in"
                    className="card-header-1"
                    titleTypographyProps={{ variant: "subtitle1" }}
                    title={t("signIn")}
                  />
                  <form onSubmit={this.onSubmit}>
                    <CardContent>
                      <FormControl className="login-form-control">
                        <Grid container spacing={1} className="login-field-container">
                          <Grid item xs={1}>
                            <EmailIcon className="form-icon form-icon-fill" />
                          </Grid>
                          <Grid item xs={11} >
                            <TextField
                              p={2}
                              type="email"
                              label={t("email")}
                              placeholder={t("email")}
                              className="input-filed"
                              error={errors.email ? true : false}
                              helperText={errors.email}
                              autoComplete="off"
                              value={this.state.email}
                              onChange={this.onChange}
                              name="email"
                              id="email"
                              inputProps={{ pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$", title: i18n.t('validEmailAddress') }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                      <FormControl className="login-form-control">
                        <Grid container spacing={1} className="login-field-container">
                          <Grid item xs={1}>
                            <LockIcon className="form-icon form-icon-fill" />
                          </Grid>
                          <Grid item xs={11} >
                            <TextField
                              p={2}
                              className="input-filed"
                              label={t("password")}
                              placeholder={t("password")}
                              error={errors.password ? true : false}
                              helperText={errors.password}
                              autoComplete="off"
                              value={this.state.password}
                              onChange={this.onChange}
                              name="password"
                              id="password"
                              type={isPasswordShown ? "text" : "password"}
                              InputProps={{
                                title: i18n.t("fillPasswordField"),
                                endAdornment: (
                                  <InputAdornment position="end" >
                                    <IconButton
                                      id='password-icon'
                                      tabindex="-1"
                                      className="password-icon"
                                      aria-label="toggle password visibility"
                                      onClick={this.togglePasswordVisiblity}
                                    >
                                      {isPasswordShown ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>

                      <Grid container spacing={1}>
                        <Grid item xs={4} sm={6}>
                          <div className="keepLoggedIn">
                            {/* Keep me logged in */}
                          </div>
                        </Grid>
                        <br />
                        <Grid item xs={8} sm={6}>
                          <div id='forgot-password'>
                            <a
                              href="/auth/emailVerification"
                              className="forgotPassword">
                              {t("forgotYourPassword")}
                            </a>
                          </div>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={1} justify="center">
                        <Button
                          id='sign-in-button'
                          className="login-button"
                          variant="contained"
                          type="submit"
                        >
                          {" "}
                          {t("signIn")}{" "}
                        </Button>
                      </Grid>
                      <br />
                      <br />
                      <Grid container spacing={1} justify="center">
                        <Grid item>
                          <div className="signin-text" id='sign-up-link'>
                            {t("noAccount")}{" "}
                            <Link className="link" to="/auth/register">{t("signUp")}</Link>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </form>
                  <CardActionArea></CardActionArea>
                </Card>
              </Grid>
            </Grid>

            {/* ====================== */}
          </Grid>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
  errors: state.errors,
});

export default compose(withTranslation(), connect(mapStateToProps, { login }))(Login);