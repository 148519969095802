import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { I18nextProvider } from 'react-i18next';
import i18n from './components/Util/i18n';

ReactDOM.render(
		<I18nextProvider i18n={i18n}>
    		<App />
  		</I18nextProvider>, document.getElementById('root'));

